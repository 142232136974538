import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import AdditionalResources from '../../components/AdditionalResources';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import Callout from '../../components/Callout';
import { useTranslation } from 'react-i18next';

const SuccessStories = ({ data, pageContext }) => {
  const { t } = useTranslation('employees');

  const textOverlay = (
    <div className="employees__text_overlay_container">
      <Container fullWidth={true}>
        <div className="employees__text_overlay_inner_container">
          <div className="employees__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="employees__text_overlay_title">
            {t('heroTitle1')}{' '}
          </span>
          <span className="employees__text_overlay_title2 text-bold color-light-blue">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  return (
    <Layout title="Success Stories" className={pageContext.lang}>
      <Hero mediaSource="img-lead-employees-1.jpg" textOverlay={textOverlay} />
      <Section>
        {/* Intro */}
        <Container>
          <Row>
            <Column size={9}>
              <h2>{t('successStoriesIntroHeading').toUpperCase()}</h2>
              <p>{t('successStoriesIntroBody')}</p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        {/* Success Stories */}
        <Container className="color-mid-blue success-stories__container">
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row
              withDividers
              verticalAlignCenter
              className="success-stories__row"
            >
              <Column size={5} className="success-stories__quote">
                <p>{t('successStoriesQuote1')}</p>
                {t('successStoriesAuthor1') !== 'successStoriesAuthor1' && (
                  <p>
                    <em>{t('successStoriesAuthor1')}</em>
                  </p>
                )}
              </Column>
              <Column
                size={6}
                className="success-stories__quote success-stories__quote"
              >
                <p>{t('successStoriesQuote2')}</p>
                {t('successStoriesAuthor2') !== 'successStoriesAuthor2' && (
                  <p>
                    <em>{t('successStoriesAuthor2')}</em>
                  </p>
                )}
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row
              withDividers
              verticalAlignCenter
              className="success-stories__row"
            >
              <Column
                size={7}
                className="success-stories__quote success-stories__quote"
              >
                <p className=" pr-2">{t('successStoriesQuote3')}</p>
                {t('successStoriesAuthor3') !== 'successStoriesAuthor3' && (
                  <p>
                    <em>{t('successStoriesAuthor3')}</em>
                  </p>
                )}
              </Column>
              <Column
                size={5}
                className="success-stories__quote success-stories__quote"
              >
                <p>{t('successStoriesQuote4')}</p>
                {t('successStoriesAuthor4') !== 'successStoriesAuthor4' && (
                  <p>
                    <em>{t('successStoriesAuthor4')}</em>
                  </p>
                )}
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row
              withDividers
              verticalAlignCenter
              className="success-stories__row"
            >
              <Column size={5} className="success-stories__quote">
                <p>{t('successStoriesQuote5')}</p>
                {t('successStoriesAuthor5') !== 'successStoriesAuthor5' && (
                  <p>
                    <em>{t('successStoriesAuthor5')}</em>
                  </p>
                )}
              </Column>
              <Column
                size={7}
                className="success-stories__quote success-stories__quote"
              >
                <p>{t('successStoriesQuote6')}</p>
                {t('successStoriesAuthor6') !== 'successStoriesAuthor6' && (
                  <p>
                    <em>{t('successStoriesAuthor6')}</em>
                  </p>
                )}
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row
              withDividers
              verticalAlignCenter
              className="success-stories__row"
            >
              {t('successStoriesQuote7') !== 'successStoriesQuote7' && (
                <Column size={7} className="success-stories__quote">
                  <p>{t('successStoriesQuote7')}</p>
                  {t('successStoriesAuthor7') !== 'successStoriesAuthor7' && (
                    <p>
                      <em>{t('successStoriesAuthor7')}</em>
                    </p>
                  )}
                </Column>
              )}
              {t('successStoriesQuote8') !== 'successStoriesQuote8' && (
                <Column
                  size={5}
                  className="success-stories__quote success-stories__quote"
                >
                  <p>{t('successStoriesQuote8')}</p>
                  {t('successStoriesAuthor8') !== 'successStoriesAuthor8' && (
                    <p>
                      <em>{t('successStoriesAuthor8')}</em>
                    </p>
                  )}
                </Column>
              )}
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <Section className="pb-0">
        {/* CALLOUT */}
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container fullWidth>
            <Callout
              to="https://abbottegc.benevity.org/user/login"
              className="callout--volunteer"
            >
              <div
                dangerouslySetInnerHTML={{ __html: t('resourcesCallout') }}
              />
            </Callout>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Section>
          {/* Related Resources */}
          <Container>
            <h2 className="h3 text-serif">
              {t('successStoriesRelatedResourcesHeading')}
            </h2>
            <Row>
              <Column size={6}>
                <AdditionalResources
                  img="img-add-resources-employees-get-started.jpg"
                  heading={t('successStoriesRelatedResourcesText1')}
                  to={'/employees'}
                />
              </Column>
              <Column size={6}>
                <AdditionalResources
                  img="img-add-resources-employee-volunteers.jpg"
                  heading={t('successStoriesRelatedResourcesText2')}
                  to={'/employees/volunteers'}
                ></AdditionalResources>
              </Column>
            </Row>
          </Container>
        </Section>
      </ToggleClassOnVisible>
    </Layout>
  );
};

export default SuccessStories;
